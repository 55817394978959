<template>
  <b-card class="ks">
    <b-card-body id="print">
      <b-row>
        <b-col md="6">
          <h4 class="bold">Incumbent Code</h4>
          <h6 class="d-flex align-items-center">
            <feather-icon
              icon="CircleIcon"
              size="20"
              class="text-danger"
              style="margin-right: 5px"
            />Not Promotable
          </h6>
          <h6 class="d-flex align-items-center">
            <feather-icon
              icon="CircleIcon"
              size="20"
              class="text-success"
              style="margin-right: 5px"
            />Promotable at least 2 salary grades
          </h6>
          <h6 class="d-flex align-items-center">
            <feather-icon
              icon="CircleIcon"
              size="20"
              class="text-secondary"
              style="margin-right: 5px"
            />New to position in last 12 month
          </h6>
        </b-col>
        <b-col md="6">
          <h4 class="bold">Succession Readiness Key</h4>
          <div class="readiness">
            <table>
              <tr>
                <th class="text-center">N</th>
                <td>= Ready & Avaible Now</td>
              </tr>
              <tr>
                <th class="text-center">1</th>
                <td>= Ready & Avaible 1 Year</td>
              </tr>
              <tr>
                <th class="text-center">2</th>
                <td>= Ready & Avaible 2 Year</td>
              </tr>
              <tr>
                <th class="text-center">2+</th>
                <td>= Ready & Avaible in More than 2 Year</td>
              </tr>
            </table>
          </div>
        </b-col>
      </b-row>

      <table class="mt-3 table-b" v-if="setup == false">
        <tr>
          <th>KEY POSITION</th>
          <th>INCUMBENT NAME</th>
          <th>POTENTIAL SUCCESSOR NAME (MAX 3)</th>
          <th>SUCCESSORS READINESS</th>
        </tr>
        <tr v-for="(suc, index) in keySuccessions" :key="index">
          <td>
            <h6>{{ JSON.parse(suc.key_position).job_level }}</h6>
            <span>{{ JSON.parse(suc.key_position).job_position }}</span>
          </td>
          <td
            :id="`incumbent-${index}`"
            :class="
              suc.color != null ? `incumbent-${suc.color}` : 'incumbent-grey'
            "
          >
            <h6 class="text-light">{{ suc.incumbent_name }}</h6>
            <div class="incumbent-code">
              <div>
                <span>Select Incumbent Code</span>
              </div>
              <div class="d-flex align-items-center">
                <div
                  id="red"
                  @click="changeIncumbentColor('red', index)"
                  class="incumbent-red"
                ></div>
                <div
                  id="green"
                  @click="changeIncumbentColor('green', index)"
                  class="incumbent-green"
                ></div>
                <div
                  id="grey"
                  @click="changeIncumbentColor('grey', index)"
                  class="incumbent-grey"
                ></div>
              </div>
            </div>
          </td>
          <td class="succ">
            <div class="succ-wrapper" v-if="suc.successor_1_name == null">
              <select
                type="text"
                v-model="potential_1[index]"
                class="redd_form"
              >
                <option
                  v-for="(emp, index) in suc.potential_successor_name"
                  :key="index"
                  :value="emp"
                >
                  {{ emp }}
                </option>
              </select>
              <select
                type="text"
                v-model="potential_2[index]"
                class="redd_form"
              >
                <option
                  v-for="(emp, index) in suc.potential_successor_name"
                  :key="index"
                  :value="emp"
                >
                  {{ emp }}
                </option>
              </select>
              <select
                type="text"
                v-model="potential_3[index]"
                class="redd_form"
              >
                <option
                  v-for="(emp, index) in suc.potential_successor_name"
                  :key="index"
                  :value="emp"
                >
                  {{ emp }}
                </option>
              </select>
            </div>
            <div class="succ-wrapper" v-else>
              <select
                type="text"
                v-model="potential_1[index]"
                class="redd_form"
              >
                <option
                  v-for="(emp, index) in successors"
                  :key="index"
                  :value="emp"
                  :selected="emp == suc.successor_1_name"
                >
                  {{ emp }}
                </option>
              </select>
              <select
                type="text"
                v-model="potential_2[index]"
                class="redd_form"
              >
                <option
                  v-for="(emp, index) in successors"
                  :key="index"
                  :value="emp"
                  :selected="emp == suc.successor_2_name"
                >
                  {{ emp }}
                </option>
              </select>
              <select
                type="text"
                v-model="potential_3[index]"
                class="redd_form"
              >
                <option
                  v-for="(emp, index) in successors"
                  :key="index"
                  :value="emp"
                  :selected="emp == suc.successor_3_name"
                >
                  {{ emp }}
                </option>
              </select>
            </div>
          </td>
          <td class="redd">
            <div class="redd-wrapper">
              <select
                type="text"
                v-model="suc.successor_1_readiness"
                class="redd_form"
              >
                <option value="N">N</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="2+">2+</option>
              </select>
              <select
                type="text"
                v-model="suc.successor_2_readiness"
                class="redd_form"
              >
                <option value="N">N</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="2+">2+</option>
              </select>
              <select
                type="text"
                v-model="suc.successor_3_readiness"
                class="redd_form"
              >
                <option value="N">N</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="2+">2+</option>
              </select>
            </div>
          </td>
        </tr>
      </table>
      <table class="mt-3 table-b" v-else>
        <tr>
          <th>KEY POSITION</th>
          <th>INCUMBENT NAME</th>
          <th>POTENTIAL SUCCESSOR NAME (MAX 3)</th>
          <th>SUCCESSORS READINESS</th>
        </tr>
        <tr v-for="(suc, index) in setupKeySuccessions" :key="index">
          <td>
            <span>{{ suc.department }}</span>
            <h6>{{ suc.position }}</h6>
          </td>
          <td
            :id="`incumbent-${index}`"
            :class="
              suc.color != null ? `incumbent-${suc.color}` : 'incumbent-grey'
            "
          >
            <h6 class="text-light">{{ suc.incumbent }}</h6>
            <div class="incumbent-code">
              <div>
                <span>Select Incumbent Code</span>
              </div>
              <div class="d-flex align-items-center">
                <div
                  id="red"
                  @click="changeIncumbentColor('red', index)"
                  class="incumbent-red"
                ></div>
                <div
                  id="green"
                  @click="changeIncumbentColor('green', index)"
                  class="incumbent-green"
                ></div>
                <div
                  id="grey"
                  @click="changeIncumbentColor('grey', index)"
                  class="incumbent-grey"
                ></div>
              </div>
            </div>
          </td>
          <td class="succ">
            <div class="succ-wrapper" v-if="suc.successor_1_name == null">
              <select
                type="text"
                v-model="potential_1[index]"
                class="redd_form"
              >
                <option
                  v-for="(emp, index) in suc.successors"
                  :key="index"
                  :value="emp"
                >
                  {{ emp }}
                </option>
              </select>
              <select
                type="text"
                v-model="potential_2[index]"
                class="redd_form"
              >
                <option
                  v-for="(emp, index) in suc.successors"
                  :key="index"
                  :value="emp"
                >
                  {{ emp }}
                </option>
              </select>
              <select
                type="text"
                v-model="potential_3[index]"
                class="redd_form"
              >
                <option
                  v-for="(emp, index) in suc.successors"
                  :key="index"
                  :value="emp"
                >
                  {{ emp }}
                </option>
              </select>
            </div>
            <div class="succ-wrapper" v-else>
              <select
                type="text"
                v-model="potential_1[index]"
                class="redd_form"
              >
                <option
                  v-for="(emp, index) in successors"
                  :key="index"
                  :value="emp"
                  :selected="emp == suc.successor_1_name"
                >
                  {{ emp }}
                </option>
              </select>
              <select
                type="text"
                v-model="potential_2[index]"
                class="redd_form"
              >
                <option
                  v-for="(emp, index) in successors"
                  :key="index"
                  :value="emp"
                  :selected="emp == suc.successor_2_name"
                >
                  {{ emp }}
                </option>
              </select>
              <select
                type="text"
                v-model="potential_3[index]"
                class="redd_form"
              >
                <option
                  v-for="(emp, index) in successors"
                  :key="index"
                  :value="emp"
                  :selected="emp == suc.successor_3_name"
                >
                  {{ emp }}
                </option>
              </select>
            </div>
          </td>
          <td class="redd">
            <div class="redd-wrapper">
              <select
                type="text"
                v-model="suc.successor_1_readiness"
                class="redd_form"
              >
                <option value="N">N</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="2+">2+</option>
              </select>
              <select
                type="text"
                v-model="suc.successor_2_readiness"
                class="redd_form"
              >
                <option value="N">N</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="2+">2+</option>
              </select>
              <select
                type="text"
                v-model="suc.successor_3_readiness"
                class="redd_form"
              >
                <option value="N">N</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="2+">2+</option>
              </select>
            </div>
          </td>
        </tr>
      </table>
    </b-card-body>

    <div class="text-right mt-3">
      <b-button variant="primary" size="sm" @click="print()">
        <feather-icon icon="FileIcon" /> Export as PDF
      </b-button>
    </div>
  </b-card>
</template>

<style scoped>
.ks .readiness {
  padding: 0.5rem 1rem;
  border: solid 1px #000;
  width: 50%;
  font-size: 11px;
  font-weight: 500;
  border-radius: 0.5rem;
}

.ks .table-b th {
  width: calc(100% / 5);
  border-collapse: collapse;
  font-size: 12px;
  text-align: center;
  color: #004080;
}

.ks .table-b td {
  width: calc(100% / 5);
  border-collapse: collapse;
  border: solid 2px #57585a;
  padding: 0.5rem;
}

.ks .table-b td h6 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
}

.ks .table-b td,
h6,
span {
  font-size: 12px;
}

.ks .incumbent {
  background: #57585a;
}

.ks .incumbent-code {
  padding: 0.3rem 0.7rem;
  border: solid 1px #57585a;
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
}

.ks .incumbent-code span {
  font-size: 11px;
  font-weight: 500;
}

.ks .incumbent-red {
  width: 30px;
  height: 10px;
  background-color: #ff0000;
  margin-right: 0.5rem;
  cursor: pointer;
}

.ks .incumbent-green {
  width: 30px;
  height: 10px;
  background-color: #00ff00;
  margin-right: 0.5rem;
  cursor: pointer;
}

.ks .incumbent-grey {
  width: 30px;
  height: 10px;
  background-color: #808080;
  margin-right: 0.5rem;
  cursor: pointer;
}

.ks .succ {
  padding: 0px !important;
  margin: 0px !important;
}

.ks .succ-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.ks .succ_form {
  border: solid 1px #57585a;
  padding: 0.3rem;
}

.ks .redd {
  padding: 0px !important;
  margin: 0px !important;
}

.ks .redd-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.ks .redd_form {
  padding: 0.3rem;
  font-size: 12px;
  font-weight: 500;
  color: #57585a;
  background: #fff;
  cursor: pointer;
}
</style>

<script>
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default {
  data() {
    return {
      successors: [],
      keySuccessions: [],
      setupKeySuccessions: [],
      setup: true,
      incumbentCodes: [],
      potential_1: [],
      potential_2: [],
      potential_3: [],
      readiness_1: [],
      readiness_2: [],
      readiness_3: [],
    };
  },

  mounted() {
    this.setupKeySuccession();
  },

  methods: {
    setupKeySuccession() {
      this.callApi({
        url: "key-successions/fetch",
        method: "GET",
        success: (res) => {
          if (res.result.key_succession) {
            res.result.forEach((item) => {
              this.keySuccessions.push(item);
            });

            this.setup = false;
          } else {
            res.result.map((item) => {
              this.setupKeySuccessions.push(item);
              this.setup = true;
            });
          }
        },
      });
    },

    changeIncumbentColor(color, index) {
      document.querySelector("#incumbent-" + index).style.backgroundColor =
        color;

      this.incumbentCodes[index] = color;
    },

    print() {
      const doc = new jsPDF();

      html2canvas(document.querySelector("#print")).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = 210;
        const pageHeight = 297;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let position = 0;

        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        position -= pageHeight;

        while (position > -((canvas.height * imgWidth) / canvas.width)) {
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          position -= pageHeight;
        }

        doc.save("Talent-Card.pdf");
      });

      // save to Database
      let datas = [];
      this.incumbentCodes.forEach((item, index) => {
        datas[index] = {
          department_id: JSON.parse(localStorage.getItem("sw_auth_data"))
            .department_id,
          key_position: JSON.stringify(this.keySuccessions[index].key_position),
          incumbent_name: this.keySuccessions[index].incumbent_name,
          color: item,
          successor_1_name: this.potential_1[index],
          successor_2_name: this.potential_2[index],
          successor_3_name: this.potential_3[index],
          successor_1_readiness: this.readiness_1[index],
          successor_2_readiness: this.readiness_2[index],
          successor_3_readiness: this.readiness_3[index],
        };
      });

      this.callApi({
        url: "key-successions/create",
        method: "POST",
        data: datas,
        success: (res) => {
          console.log(res);
        },
      });
    },
  },
};
</script>